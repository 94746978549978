import React, { useRef, useState } from "react";
import { Button, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getGraph } from "../api/api";
import Explorer from "./Explorer";

function ExplorerContainer() {
  const termInput = useRef();
  const [term, setTerm] = useState("");

  const { data, isLoading } = useQuery(
    ["graph", { term }],
    () => getGraph({ term }),
    {
      enabled: !!term,
    }
  );

  return (
    <div>
      <div className="p-4 d-flex flex-column align-items-center justify-content-center">
        {!term && (
          <h2 className="text-center">
            Search by treatments or adverse reactions...
          </h2>
        )}

        <InputGroup className={!term ? "my-3 w-75" : "mb-3"} size="lg">
          <FormControl
            ref={termInput}
            placeholder={
              !term
                ? "eg.: ivermectin"
                : "Search by treatments or adverse reactions..."
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setTerm(termInput.current.value);
              }
            }}
          />
          <InputGroup.Append>
            <Button
              onClick={() => {
                setTerm(termInput.current.value);
              }}
              variant="primary"
            >
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
      {!!term && (
        <>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "30em" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {!!data ? (
                <Explorer graph={data} />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "30em" }}
                >
                  <p>
                    Oh no! We couldn't find RCTs including "{term}" on its
                    content.
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ExplorerContainer;
