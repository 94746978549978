const get = async (endpoint) => {
  const response = await fetch(
    `https://rct.coronavis-srv01.nuvem.ufrgs.br${endpoint}`
  );
  if (!response.ok) {
    throw new Error("response is not ok");
  }

  return response.json();
};

export async function getGraph({ term }) {
  const { data } = await get(`/graph/${encodeURIComponent(term)}`);

  return data.find((x) => x._id === "graph");
}

const post = async (endpoint, formBody) => {
  const response = await fetch(
    `https://rct.coronavis-srv01.nuvem.ufrgs.br${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: formBody.toString(),
      json: true
    }
  )
  
  if (!response.ok) {
    throw new Error("response is not ok");
  }
  return response.json();
}


export async function postClinicalTrials({ docs }) {
  var formBody = new URLSearchParams();
    for (let doc in docs){
      formBody.append('ids', docs[doc]);
    }
  const { data } = await post(`/clinicaltrial`, formBody);
  
  return data;
}