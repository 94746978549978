import React from "react";
import styled from "styled-components";

function Legend({ color }) {
  return (
    <Container>
      {[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((x) => (
        <Block key={x} color={color(x)} />
      ))}
    </Container>
  );
}

export default Legend;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Block = styled.div`
  width: 18px;
  height: 18px;
  background: ${(props) => props.color};
`;
