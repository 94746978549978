import { chunk, orderBy, get } from "lodash";
import React, { useMemo, useState } from "react";
import { Badge, ListGroup, Pagination, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from "styled-components";
import { postClinicalTrials } from "../api/api";

const PAGE_SIZE = 8;

const STATUS_ORDER = {
  "Not yet recruiting": 1,
  "Recruiting": 2,
  "Enrolling by invitation": 3,
  "Active, not recruiting": 4,
  "Suspended": 3,
  "Terminated": 5,
  "Completed": 6,
  "Withdrawn": 2,
};


function DocList({ docs }) {
  const [pageNumber, setPageNumber] = useState(0);

  const { data, isLoading } = useQuery(
    ["rcts", { docs }],
    () => postClinicalTrials( { docs } ),
    {
      enabled: !!docs
    }
  );

  const pages = useMemo(() => {
    if (!data) {
      return [];
    }

    return chunk(
      orderBy(
        data,
        [
          (x) => get(STATUS_ORDER, x['status'], 0),
          (x) => x['completion_date'],
          (x) => x['enrollment'],
          (x) => x['has_results']
        ],
        ["desc", "desc", "desc", "desc"]
      ),
      PAGE_SIZE
    );

  }, [data]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "30em" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Container>
      <ListGroup activeKey={"-1"}>
        {pages[pageNumber].map((x, i) => {
          const doc = x;
          const docId = doc['_id'].replace(".xml", "");

          return (
            <ListGroup.Item
              key={i}
              eventKey={i}
              action
              href={`https://ClinicalTrials.gov/show/${docId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Title>{docId}</Title><br/>
              {doc.title} <Badge variant={"info"}>{doc.status}</Badge>{" "}
              <Badge
                variant={
                  doc.has_results ? "success" : "light"
                }
              >
                {doc.has_results ? "Has results" : ""}
              </Badge>{" "}
              <Badge variant={"dark"}>Start date: {new Date(doc["start_date"]).toLocaleDateString('en-US', {year:'numeric', day:'2-digit', month:'short'})}</Badge>{" "}
              <Badge variant={"dark"}>Completion date: {new Date(doc["completion_date"]).toLocaleDateString('en-US', {year:'numeric', day:'2-digit', month:'short'})}</Badge>{" "}
              <Badge variant={"secondary"}>
                Enrollment: {doc["enrollment"]}
              </Badge>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      <div className="d-flex justify-content-between mt-2">
        <Pagination>
          <Pagination.Prev
            disabled={pageNumber === 0}
            onClick={() => setPageNumber(Math.max(0, pageNumber - 1))}
          />
          <Pagination.Next
            disabled={pageNumber === pages.length - 1}
            onClick={() =>
              setPageNumber(Math.min(pages.length - 1, pageNumber + 1))
            }
          />
        </Pagination>

        <span>
          Showing {1 + pageNumber * PAGE_SIZE}-
          {pageNumber * PAGE_SIZE + PAGE_SIZE} of {docs.length}
        </span>
      </div>
    </Container>
  );
}

export default DocList;

const Container = styled.div`
  .list-group {
    font-size: 12px;
  }
`;

const Title = styled.text`
  font-size: 1rem;
  font-weight: 500;
  font-family: "Montserrat";
  line-height: 1.2;
  fill: #5a5a5a;
`;