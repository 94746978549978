import { localPoint } from "@visx/event";
import { Graph as NetworkGraph } from "@visx/network";
import { useTooltip } from "@visx/tooltip";
import * as d3 from "d3";
import {
  castArray, flatMap, intersection, isArray,
  isEqual, uniq
} from "lodash";
import { darken } from "polished";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import DocList from "./DocList";
import Legend from "./Legend";

function Explorer({ graph }) {
  const radius = 10;
  const width = 950;
  const maxNumberOfNodes = Math.max(
    graph.adverseEvents.length,
    graph.treatments.length
  );
  const height = 150 + 35 * maxNumberOfNodes + 15 * maxNumberOfNodes;

  const svgRef = useRef(null);
  const tooltipTimeout = useRef(null);

  const [selectedNode, setSelectedNode] = useState(null);

  const {
    tooltipData,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const maxNumberOfDocsForTypes = useMemo(
    () => Math.max(...graph.treatments.map((x) => x.total)),
    [graph]
  );
  const maxNumberOfDocsForConcepts = useMemo(
    () => Math.max(...graph.adverseEvents.map((x) => x.total)),
    [graph]
  );
 
  const docs = useMemo(
    () =>
      uniq([
        ...flatMap(graph.treatments, (x) => x.files),
        ...flatMap(graph.adverseEvents, (x) => x.files),
      ]),
    [graph]
  );

  const styles = {
    panel: {
      marginTop: -35
    }
  }

  const [graphData, nodesById, linksById] = useMemo(() => {
    const nodesById = {};

    let treatmentIndex = 0;
    graph.treatments.forEach((payload) => {
      const { _id: id, files: docs, total} = payload;
      if (id in nodesById) {
        return;
      }

      nodesById[id] = {
        x: 200,
        y: 55 + 10 * treatmentIndex + 20 * treatmentIndex,
        payload: {
          ...payload,
          id,
          docs,
          total,
        },
      };

      treatmentIndex += 1;
    });

    let adverseEventIndex = 0;
    graph.adverseEvents.forEach((payload) => {
      const { _id: id, files: docs, total} = payload;
      if (id in nodesById) {
        return;
      }

      nodesById[id] = {
        x: 450,
        y: 55 + 10 * adverseEventIndex + 20 * adverseEventIndex,
        payload: {
          ...payload,
          id,
          docs,
          total,
        },
      };

      adverseEventIndex += 1;
    });

    const linksById = {};
    const links = graph.edges.map((payload) => {
      const docs = payload.files;

      return {
        source: nodesById[payload.treatment],
        target: nodesById[payload.adverseEvent],
        payload: { docs },
      };
    });

    links.forEach((link) => {
      const sourceId = link.source.payload.id;
      const targetId = link.target.payload.id;

      if (!linksById[sourceId]) {
        linksById[sourceId] = [];
      }
      linksById[sourceId].push(targetId);

      if (!linksById[targetId]) {
        linksById[targetId] = [];
      }
      linksById[targetId].push(sourceId);
    });

    return [{ nodes: Object.values(nodesById), links }, nodesById, linksById];
  }, [graph]);

  const renderNodeComponent = useCallback(
    ({ node }) => {
      const {
        payload: { category, id, docs, name, total },
      } = node;

      const isSelected = castArray(selectedNode).includes(id);
      const isLinked = ((_id) => {
        if (isArray(_id)) {
          return _id.includes(id);
        }

        return _id ? linksById[_id].includes(id) || _id === id : true;
      })(tooltipData || selectedNode);

      const handleClick = () => {
        setSelectedNode(selectedNode === id ? null : id);
      };

      const handleMouseEnter = (event) => {
        clearTimeout(tooltipTimeout.current);
        const coords = localPoint(event.target.ownerSVGElement, event);
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: coords.y,
          tooltipData: id,
        });
      };

      const handleMouseOut = () => {
        clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = setTimeout(hideTooltip, 300);
      };
      if (category === "Treatment") {
        return (
          <>
            <text x={-25} y={radius / 2} textAnchor="end">
              {name}
            </text>
            <TypeNode
              color={d3.interpolatePuBu(docs.length / maxNumberOfDocsForTypes)}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseOut={handleMouseOut}
              width={radius * 2}
              height={radius * 2}
              x={-1 * radius}
              y={-1 * radius}
              opacity={isLinked ? 1 : 0.5}
              strokeWidth={isSelected ? 2 : 1}
              stroke={isSelected ? "red" : "#0c0c0c"}
              rx={4}
            />
            <text x={15} y={radius / 2} textAnchor="start">
              {total}
            </text>
          </>
        );
      }
      return (
        <>
          <text x={25} y={radius / 2} textAnchor="start">
            {name}
          </text>
          <ConceptNode
            color={d3.interpolateYlGn(docs.length / maxNumberOfDocsForConcepts)}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseOut={handleMouseOut}
            width={radius * 2}
            height={radius * 2}
            x={-1 * radius}
            y={-1 * radius}
            opacity={isLinked ? 1 : 0.1}
            strokeWidth={isSelected ? 2 : 1}
            stroke={isSelected ? "red" : "#0c0c0c"}
            rx={4}
          />
          <text x={-15} y={radius / 2} textAnchor="end">
            {total}
          </text>
        </>
      );
    },
    [
      hideTooltip,
      linksById,
      maxNumberOfDocsForConcepts,
      maxNumberOfDocsForTypes,
      selectedNode,
      showTooltip,
      tooltipData,
    ]
  );

  const renderLinkComponent = useCallback(
    ({
      link: {
        source,
        target,
        payload: { docs },
      },
    }) => {
      const isLinkSelected =
        (isArray(selectedNode) && isEqual(selectedNode, [source.payload.id, target.payload.id]))
        || (selectedNode && (source.payload.id === selectedNode || target.payload.id === selectedNode));

      const isLinked = ((_id) => {
        if (isArray(_id)) {
          return isEqual(_id, [source.payload.id, target.payload.id]);
        }
        return (_id
          ? ((source.payload.id === _id || target.payload.id === _id)
          || (isArray(selectedNode) && isEqual(selectedNode, [source.payload.id, target.payload.id]))
          || (selectedNode && (source.payload.id === selectedNode || target.payload.id === selectedNode))
          )
           : false);
      })(tooltipData || selectedNode);

      //const strokeWidth = 1 + (docs.length / maxNumberOfDocsForLinks) * 10;

      return (
        <Edge
          x1={source.x}
          y1={source.y}
          x2={target.x}
          y2={target.y}
          strokeWidth={3}
          color={isLinkSelected ? "red" : "#999"}
          strokeOpacity={isLinked ? 0.8 : 0.0}
          onClick={() =>
            setSelectedNode([source.payload.id, target.payload.id])
          }
        />
      );
    },
    [selectedNode, tooltipData]
  );

  return (
    <Container fluid className="pt-2">
      <Row>
        <Col sm="8">
          <GraphContainer style={{ width: "100%", height }}>
            <svg ref={svgRef} width={"100%"} height={height}>
              <Title x={40} y={22} textAnchor="left">
                Treatments
              </Title>
              <Title x={480} y={22} textAnchor="right">
                Adverse Reactions
              </Title>
              <rect
                width={width}
                height={height}
                rx={4}
                fill="transparent"
                onClick={() => {
                  setSelectedNode(null);
                  hideTooltip();
                }}
              />
              <NetworkGraph
                graph={graphData}
                nodeComponent={renderNodeComponent}
                linkComponent={renderLinkComponent}
              />
            </svg>

            <LegendWrapper position="left-top">
              <LegendTicks>
                <LegendTick>{maxNumberOfDocsForTypes}</LegendTick>
                  <Legend color={d3.interpolatePuBu} />
                <LegendTick>0</LegendTick>
              </LegendTicks>
            </LegendWrapper>
            <LegendWrapper position="right-top">
              <LegendTicks>
                <LegendTick>{maxNumberOfDocsForConcepts}</LegendTick>
                  <Legend color={d3.interpolateYlGn} />
                <LegendTick>0</LegendTick>
              </LegendTicks>
            </LegendWrapper>
          </GraphContainer>
        </Col>
        <Col sm="4" style={styles.panel}>
          <h4>Clinical Trials</h4>

          <ListGroup>
            <DocList
              key={castArray(selectedNode || "default").join("-")}
              docs={
                selectedNode
                  ? intersection(
                      ...castArray(selectedNode).map(
                        (x) => nodesById[x].payload.docs
                      )
                    )
                  : docs
              }
            />
          </ListGroup>

          <FootNote>
            Powered by:{" "}
            <a
              href={"https://clinicaltrials.gov/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Clinical Trials
            </a>
            {", "}
            <a
              href={
                "https://www.drugbank.com/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              DrugBank
            </a>
            {", and "}
            <a
              href={
                "https://www.nlm.nih.gov/research/umls/licensedcontent/umlsknowledgesources.html"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              UMLS
            </a>
          </FootNote>
        </Col>
      </Row>
    </Container>
  );
}

export default Explorer;

const GraphContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

const Title = styled.text`
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Montserrat";
  line-height: 1.2;
  fill: #5a5a5a;
`;

const TypeNode = styled.rect`
  fill: ${(props) => props.color};
  cursor: pointer;

  :hover {
    fill: ${(props) => darken(0.1, props.color)};
  }
`;

const ConceptNode = styled.rect`
  cursor: pointer;
  fill: ${(props) => props.color};

  :hover {
    fill: ${(props) => darken(0.1, props.color)};
  }
`;

const Edge = styled.line`
  cursor: pointer;
  stroke: ${(props) => props.color};

  :hover {
    stroke: ${(props) => darken(0.1, props.color)};
  }
`;

const LegendTicks = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between; 
  margin: 0 25px;
`;

const LegendTick = styled.div`
  font-size: 13px;
  padding: 0 5px;
`;

const positionMixins = {
  "right-bottom": css`
    bottom: 20px;
    right: 20px;

    ${LegendTicks} {
      text-align: right;
    }
  `,
  "left-bottom": css`
    bottom: 20px;
    left: 20px;
    flex-direction: row-reverse;
  `,
  "right-top": css`
    top: -30px;
    left: 455px;

    ${LegendTicks} {
      text-align: right;
    }
  `,
  "left-top": css`
    top: -30px;
    left: -30px;
    flex-direction: row-reverse;
  `,
};

const LegendWrapper = styled.div`
  position: absolute;
  display: flex;

  ${(props) => positionMixins[props.position]}
`;

const FootNote = styled.p`
  font-size: 10px;
`;
