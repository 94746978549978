import React, { Component } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Route } from "react-router-dom";
import ExplorerContainer from "./components/ExplorerContainer";
import About from "./components/About";
import Header from "./components/Header";
import "./App.css";

class App extends Component {

  render(){
    return (
        <div>
          <Navbar bg="light" expand="lg" className="justify-content-center">
              <Header/>
          </Navbar>
          <Nav className="NavMainMenu">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="about">About</Nav.Link>
          </Nav>
          <Container fluid className="content p-2">
            <Route exact path="/" component={ExplorerContainer} />
            <Route path="/about" component={About} />
          </Container>
        </div>
    );
  }

}

export default App;
