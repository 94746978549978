import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../assets/header.css";
import logoInf from "../assets/inf-logo-2.png";
import logoUfrgs from "../assets/ufrgs-logo-2.png";
  
export default function Header() {    
    return (
      <header className="header">
        <Container>
            <Row>
                <Col xs={6} sm={3} md={4} className="logoUfrgs">
                    <img alt="Logo UFRGS" src={logoUfrgs} />
                </Col>
                <Col xs={12} sm={6} md={4} className="title">
                    <h4 style={{
                        marginTop: "0.5rem",
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'
                    }}>Visual Exploration of Randomized Clinical Trials for COVID-19</h4>
                </Col>
                <Col xs={6} sm={3} md={4} className="logoInf">
                    <img alt="Logo INF" src={logoInf} />
                </Col>
            </Row>
        </Container>
      </header>
    );
  }
  