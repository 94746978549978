import React, { Component } from "react";
import "../App.css";

class About extends Component {

    render() {
        return(
            <div className="about">
                <div className="col">
                    <div className="row">
                        <h3>Dr. João Luiz Dihl Comba</h3>
                    </div>
                    <div className="row">
                        <p>
                        Professor at the Federal University of Rio Grande do Sul. <br/>
                        Areas of interest: visualization, mining, data integration and analysis.
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <h3>Dra. Viviane Pereira Moreira</h3>
                    </div>
                    <div className="row">
                        <p>
                        Professor at the Federal University of Rio Grande do Sul. <br/>
                        Areas of interest: information retrieval, stemming algorithms, database, opinion mining and text mining.
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <h3>Abel Corrêa Dias</h3>
                    </div>
                    <div className="row">
                        <p>
                        Doctoral student of the graduate program in computing at UFRGS.
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <h3>Felipe Pontes</h3>
                    </div>
                    <div className="row">
                        <p>
                        Master's student of the graduate program in computing at UFRGS.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export default About;